/**
 * Helper component for rendering basic checkbox inputs
 */

// import primary libraries
import React from 'react';
import PropTypes from 'prop-types';

const CheckboxInput = ({
  change
  , className
  , disabled
  , helpText
  , label
  , name
  , placeholder
  , required
  , value
  , ...inputProps
}) => {
  const isChecked = !!value;
  return (
    <div className={`p-2 rounded-md flex flex-grow items-center m-1 w-full ${!isChecked ? 'bg-transparent' : 'bg-zinc-300 font-bold'} ${className || ''}`}>
      <input
        disabled={disabled}
        className="h-5 w-5 rounded accent-indigo-600"
        checked={isChecked}
        name={name}
        onChange={e => {
          change({
            target: {
              name: name
              , value: e.target.checked
            }
          })
        }}
        placeholder={placeholder}
        required={required}
        type="checkbox"
        value={value}
        {...inputProps}
      />
      {label ? (
        <label
          htmlFor={name}
          className={`ml-4 text-lg inline-block w-full`}
        >
          {label} {required && <sup className="text-red-500">*</sup>}
          {helpText && <small className="text-xs text-gray-500"><em>{helpText}</em></small>}
        </label>
      )
        :
        null
      }
    </div>
  )
}

CheckboxInput.propTypes = {
  change: PropTypes.func.isRequired
  , disabled: PropTypes.bool
  , helpText: PropTypes.any
  , label: PropTypes.oneOfType([
    PropTypes.string
    , PropTypes.element
    , PropTypes.number
    , PropTypes.any
  ])
  , name: PropTypes.oneOfType([
    PropTypes.string
    , PropTypes.number
  ]).isRequired
  , placeholder: PropTypes.string
  , required: PropTypes.bool
  , value: PropTypes.bool.isRequired
}

CheckboxInput.defaultProps = {
  disabled: false
  , helpText: null
  , label: ''
  , placeholder: ''
  , required: false
}

export default CheckboxInput;
