/**
 * Global StudentNav component. Up to the designers to decide if should live
 * as a top-nav, fixed side, menu-prompted, etc.
 *
 * Recommended to select a good one from Tailwind UI that matches the brand
 * and edit from there.
 * https://tailwindui.com/components/application-ui/headings/page-headings
 */

// import primary libraries
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useLoggedInUser } from '../../../resources/user/authService';


import { sendLogout } from '../../../resources/user/authStore';

// import NotificationDropdown from '../../../resources/notification/components/NotificationDropdown';


const StudentNav = () => {

  // use the hook to get the loggedInUser from the authStore
  const loggedInUser = useLoggedInUser();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleLogout = async () => {
    dispatch(sendLogout()).then(response => {
      //TODO local
      localStorage.removeItem('user')
      history.push("/");
    })
  }

  // because we have conflicting routes, we need to use a custom isActive function to determine if the My Courses link should be active
  const myCoursesIsActive = (match, location) => {
    // Check if the path is exactly '/learn/courses' or if it's a subroute, but not the invites subroute
    return location.pathname.startsWith('/learn/courses') && !location.pathname.startsWith('/learn/courses/invites');
  };


  // because we're using tailwind, we can't use the activeClassName prop without some extra work
  // text-gray-800 as an active class will not override the text-gray-400 from classNames on the NavLink
  // instead we can use text-inherit to override the text-gray-400
  const navLinkClasses = "my-auto items-center align-middle flex flex-row gap-2 hover:no-underline text-gray-400 ";
  const activeNavLinkClasses = "text-inherit";

  return (
    <header className="border-b border-solid shadow-sm bg-white">
      <nav className="py-2 px-6 flex flex-row justify-between items-center">
        <ul className="list-none p-0 flex flex-row gap-6 font-semibold">
          {/* <li>
            <NavLink to="/learn" exact={true} className={navLinkClasses} activeClassName={activeNavLinkClasses}>
              <i className="fa-regular fa-house" />
              <p className={`text-inherit hidden sm:block`}>Home</p>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/learn/courses" className={navLinkClasses} activeClassName={activeNavLinkClasses} isActive={myCoursesIsActive}>
              <i className="fa-sharp fa-regular fa-memo"></i>
              <p className={`text-inherit hidden sm:block`}>My Courses</p>
            </NavLink>
          </li>
          <li>
            <NavLink to="/learn/courses/invites" className={navLinkClasses} activeClassName={activeNavLinkClasses}>
              <i className="fa-sharp fa-regular fa-envelope"></i>
              <p className={`text-inherit hidden sm:block`}>Invites</p>
            </NavLink>
          </li>
        </ul>
        {!loggedInUser ?
          <ul className="list-none p-0 flex flex-row">
            <li><NavLink to={{ pathname: "/user/login", state: { from: location } }} className="p-2 block">Sign in</NavLink></li>
            <li><NavLink to={{ pathname: "/user/register", state: { from: location } }} className="p-2 block">Register</NavLink></li>
          </ul>
          :
          <ul className="list-none p-0 flex flex-row text-xs sm:text-base">
            {/* <li><NotificationDropdown classes="p-2 block" /></li> */}
            <li className='my-auto'><p className="p-2 block">{loggedInUser.username}</p></li>
            {/* <li><NavLink to="/user/profile" className="p-2 block">My profile</NavLink></li> */}
            <li className='my-auto'><button className="p-2 block" onClick={handleLogout}>Logout</button></li>
          </ul>
        }
      </nav>
    </header>
  )
}

export default StudentNav;
