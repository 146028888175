import React from 'react';
import DefaultLayout from '../../../../global/components/layouts/DefaultLayout';

const InstructorDashboard = () => {
  return (
    <DefaultLayout title="PracticeQuest">
      <section className="p-2">
        <h1 className="p-2 md:text-center text-3xl"> Welcome to the PQ Instructor Dashboard!</h1>
        <p className="p-2 md:text-center"> Here you can manage students, courses, and quizzes. </p>
      </section>
    </DefaultLayout>
  )
}

export default InstructorDashboard;
