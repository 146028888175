/**
 * Helper component for allowing users to add and remove items from an array
 */

// import primary libraries
import React, {useState} from 'react';
import PropTypes from 'prop-types';

const ArrayEditor = ({
  change
  , name
  , disabled
  , helpText
  , items =[]
  , label
}) => {
  const [newItem, setNewItem] = useState('');

  const handleAddItem = () => {
    if(newItem) {
      change({ target: { name: 'fieldKeys', value: [...items, newItem] } });
      setNewItem('');
    }
  }

  const handleRemoveItem = (item) => {
    change({ target: { name: 'fieldKeys', value: items.filter(i => i !== item) } });
  }

  return (
    <div className="relative p-2 z-0 w-full mb-4 bg-indigo-50 border-stone-400 border rounded lg:w-auto">
      {label ? (
        <span
          htmlFor={name}
          className="px-2 text-xs absolute text-gray-500 bg-transparent z-10"
        >
          {label}
        </span>
      )
        :
        null
        
      }
      <div className="flex flex-row">
        <div className={`relative w-full mb-2 px-2 ${label ? 'pt-4 pb-1' : 'pt-2 pb-3'}`}>
          <div className="mb-6">
            <div className="flex flex-row justify-between">
              <input
                type="text"
                className="w-3/4 p-2 bg-white border rounded border-solid border-stone-400"
                name="newItem"
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
                disabled={disabled}
              />
              <button
                type="button" 
                className="btn-success disabled:bg-stone-300"
                onClick={() => handleAddItem()}
                disabled={disabled || !newItem }
              >
                Add 
              </button> 
            </div>  
          </div>
          {items.map((item, index) => ( 
            <div key={index} className="flex border-stone-400 border items-center justify-between p-2 mb-2 bg-white rounded">
              <span>{item}</span>
              <button
                type="button"
                className='text-red-800 hover:text-red-600 font-semibold'
                onClick={() => handleRemoveItem(item)}
                disabled={disabled}

              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

ArrayEditor.propTypes = {
  change: PropTypes.func.isRequired
  , disabled: PropTypes.bool
  , helpText: PropTypes.string
  , items: PropTypes.array
  , label: PropTypes.string
}

export default ArrayEditor;