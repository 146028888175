/**
 * Reads and exports the reducers as defined by each resource module
 *
 * NOTE: this facilitates adding reducers via the CLI
 */

export { default as auth } from '../resources/user/authStore.js';
export { default as user } from '../resources/user/admin/adminUserStore.js';
// export { default as product } from '../resources/product/productStore.js';
export { default as notification } from '../resources/notification/notificationStore.js';
export { default as referent } from '../resources/referent/referentStore.js';
export { default as referentCollection } from '../resources/referentCollection/referentCollectionStore.js';
export { default as question } from '../resources/question/questionStore.js';
export { default as constant } from '../resources/constant/constantStore.js';

export { default as course } from '../resources/course/courseStore.js';
export { default as courseUser } from '../resources/courseUser/courseUserStore.js';
// export { default as subject } from '../resources/subject/subjectStore.js';
export { default as tag } from '../resources/tag/tagStore.js';
export { default as assessment } from '../resources/assessment/assessmentStore.js';
export { default as userAssessment } from '../resources/userAssessment/userAssessmentStore.js';
export { default as userQuestion } from '../resources/userQuestion/userQuestionStore.js';
export { default as image} from '../resources/image/imageStore.js';
