import React from 'react';
import DefaultLayout from '../../../global/components/layouts/DefaultLayout';

const Dashboard = () => {
  return (
    <DefaultLayout title="PracticeQuest">
      <section className="p-2">
        <h1 className="p-2 md:text-center text-3xl"> Welcome to the PracticeQuest!</h1>
        <p className="p-2 md:text-center"> The best online platform for testing your knowledge! </p>
      </section>
    </DefaultLayout>
  )
}

export default Dashboard;
