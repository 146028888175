import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiUtils from '../../global/utils/api';

import {
    handleCreateFulfilled,
    handleFetchSinglePending,
    handleFetchSingleFulfilled,
    handleFetchSingleRejected,
    handleFetchListPending,
    handleFetchListFulfilled,
    handleFetchListRejected,
    shouldFetch,
    INITIAL_STATE,
    handleInvalidateQuery,
    handleInvalidateQueries,
    handleAddSingleToList,
    handleAddManyToList
} from '../../global/utils/storeUtils';

export const sendCreateImage = createAsyncThunk(
    'image/sendCreate',
    async (newImage) => {
        const endpoint = `/api/images`;
        return await apiUtils.callAPI(endpoint, 'POST', newImage);
    }
);

export const fetchSingleImage = createAsyncThunk(
    'image/fetchSingle',
    async (id) => {
        const endpoint = `/api/images/${id}`;
        return await apiUtils.callAPI(endpoint);
    }
);

export const fetchImageList = createAsyncThunk(
    'image/fetchList',
    async (listArgs) => {
        const endpoint = `/api/images/${listArgs}`;
        return await apiUtils.callAPI(endpoint);
    }
);

export const sendUpdateImage = createAsyncThunk(
    'image/sendUpdate',
    async ({ _id, ...updates }) => {
        const endpoint = `/api/images/${_id}`;
        return await apiUtils.callAPI(endpoint, 'PUT', updates);
    }
);

export const sendDeleteImage = createAsyncThunk(
    'image/sendDelete',
    async (id) => {
        const endpoint = `/api/images/${id}`;
        return await apiUtils.callAPI(endpoint, 'DELETE');
    }
);

const initialState = { ...INITIAL_STATE };

export const imageSlice = createSlice({
    name: 'image',
    initialState,
    reducers: {
        invalidateQuery: handleInvalidateQuery,
        invalidateQueries: handleInvalidateQueries,
        addImageToList: handleAddSingleToList,
        addImagesToList: handleAddManyToList
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendCreateImage.fulfilled, handleCreateFulfilled)

            .addCase(fetchSingleImage.pending, handleFetchSinglePending)
            .addCase(fetchSingleImage.fulfilled, handleFetchSingleFulfilled)
            .addCase(fetchSingleImage.rejected, handleFetchSingleRejected)
            .addCase(fetchImageList.pending, handleFetchListPending)
            .addCase(fetchImageList.fulfilled, (state, action) => handleFetchListFulfilled(state, action, 'images'))
            .addCase(fetchImageList.rejected, handleFetchListRejected)

            .addCase(sendUpdateImage.pending, handleFetchSinglePending)
            .addCase(sendUpdateImage.fulfilled, handleFetchSingleFulfilled)
            .addCase(sendUpdateImage.rejected, handleFetchSingleRejected)

            .addCase(sendDeleteImage.pending, handleFetchSinglePending)
            .addCase(sendDeleteImage.fulfilled, handleFetchSingleFulfilled)
            .addCase(sendDeleteImage.rejected, handleFetchSingleRejected)
    }
});

export const {
    invalidateQuery,
    invalidateQueries,
    addImageToList,
    addImagesToList
} = imageSlice.actions;

export const fetchListIfNeeded = (queryKey, listFetch = fetchImageList) => (dispatch, getState) => {
    const imageQuery = getState().image.listQueries[queryKey];
    if(shouldFetch(imageQuery)) {
        dispatch(listFetch(queryKey));
    } else {
        // console.log('No need to fetch, fresh query in cache');
    }
};

export const fetchSingleIfNeeded = (id, singleFetch = fetchSingleImage) => (dispatch, getState) => {
    const imageQuery = getState().image.singleQueries[id];
    if(shouldFetch(imageQuery)) {
        dispatch(singleFetch(id));
    } else {
        // console.log('No need to fetch, fresh query in cache');
    }
}

export default imageSlice.reducer;
