import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

const Landing = () => {
  return (
    <DefaultLayout title="PracticeQuest AI">
      <section className="p-2">
        <h1 className="p-2 md:text-center text-3xl"> Welcome to the PQ-AI Assessment System</h1>
        <p className="p-2 md:text-center"> Unlimited practice on the skills that matter</p>
        <p className="p-3 md:text-center"><a className="btn" href="/user/login">Sign in</a></p>
      </section>
    </DefaultLayout>
  )
}

export default Landing;
