/**
 * This is a utility to handle default API requests with the Yote server
 */

import _ from 'lodash'
// TODO: break this into separate exports so we aren't forced to import the entire set to use one method.

const apiUtils = {
  async callAPI(route, method = 'GET', body, headers = {
    'Accept': 'application/json', 'Content-Type': 'application/json',
  }) {
    const response = await fetch(route, {
      headers,
      method,
      credentials: 'same-origin',
      body: JSON.stringify(body)
    });
    if (response.ok) {
      return response.json();
    } else {
      const error = await response.json().catch(unhandledError => {
        console.error('Unhandled Error Thrown by Server. Not cool.', unhandledError);
        throw 'Something went wrong';
      });
      throw error;
    }
  },
  async rawAPI(route, method = 'GET', body, headers = {}) {
    const response = await fetch(route, {
      headers,
      method,
      credentials: 'same-origin',
      body
    });
    if (response.ok) {
      return response.json();
    } else {
      const error = await response.json().catch(unhandledError => {
        console.error('Unhandled Error Thrown by Server. Not cool.', unhandledError);
        throw 'Something went wrong';
      });
      throw error;
    }
  },
  queryStringFromObject(queryObject) {
    if (!queryObject) return "";
    if (typeof queryObject !== "object") return "";
    if (Object.keys(queryObject).length === 0) return "";
    return Object.entries(queryObject)
        .filter(entry => entry[1] && entry[1].toString().length > 0)
        .map(item => {
          return item;
        })
        .map(entry => Array.isArray(entry[1]) ? [entry[0], entry[1].join(",")] : entry)
        .map(entry => entry.join("="))
        .join("&");
  },
  objectFromQueryString(queryString) {
    queryString = queryString?.split("?")[1];
    if (!queryString) return {};
    return queryString.replace("?", "").split("&")
        .map(item => item.split("="))
        .map(item => [_.camelCase(item[0]), item[1]])
        .reduce((returnObj, item) => { return item[0].length > 0 ? { ...returnObj, [item[0]]: item[1] } : returnObj }, {});
  },
  checkListArgsReady(listArgs) {
    if (!listArgs) return false;
    if (typeof listArgs !== "object") return false;
    let listArgsReady = true;
    Object.keys(listArgs).forEach(key => {
      if ((listArgs[key] === undefined) || (Array.isArray(listArgs[key]) && listArgs[key].length === 0)) {
        listArgsReady = false;
      }
    });
    return listArgsReady;
  }
}

export default apiUtils;
