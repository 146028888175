/**
 * Reads and exports the routes as defined by the admin folder for each resource.
 *
 * NOTE: this is not currently used by the CLI. It has to be manually updated (kind of on purpose, admin is god-mode after all)
 *
 * export { default as user } from './user/admin/AdminUserRouter.jsx'
 *
 * This will give it a route path called '/admin/users'
 */

import { lazy } from 'react';

// lazy load the routes so they aren't included in the intial bundle and don't load until they are needed or until we decide to preload them (currently not preloading admin routes)
export const users = lazy(() => import('../resources/user/admin/AdminUserRouter.jsx'));
export const questions = lazy(() => import('../resources/question/admin/AdminQuestionRouter.jsx'));
export const constants = lazy(() => import('../resources/constant/admin/AdminConstantRouter.jsx'));
export const referents = lazy(() => import('../resources/referent/admin/AdminReferentRouter.jsx'));
export const referentCollections = lazy(() => import('../resources/referentCollection/admin/AdminReferentCollectionRouter.jsx'));
export const assessments = lazy(() => import('../resources/assessment/admin/AdminAssessmentRouter.jsx'));
export const tags = lazy(() => import('../resources/tag/admin/AdminTagRouter.jsx'));
// export const subjects = lazy(() => import('../resources/subject/admin/AdminSubjectRouter.jsx'));
export const images = lazy(() => import('../resources/image/admin/AdminImageRouter.jsx'));
